<template>
  <div id="popup-info" class="popup-info">
    <div class="popup-info__title" v-if="content.title" v-html="content.title"></div>
    <div class="popup-info____desc" v-if="content.desc" v-html="content.desc"></div>
    <Button parent-class="popup-info__button" v-on:click.prevent="submitForm" v-if="content.url">{{ content.button }}</Button>
  </div>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    Button,
  },
  data() {
    return {
      content: this.$store.state.infoPopup.data,
    };
  },
  methods: {
    submitForm() {
      window.open(this.content.url, "_blank");
    },
  },
  mounted() {
    Fancybox.show(
      [
        {
          src: "#popup-info",
          type: "inline",
        },
      ],
      {
        on: {
          destroy: () => {
            this.$store.commit("updateInfoPopup", {
              status: false,
              data: null,
            });
          },
        },
        dragToClose: false,
        autoFocus: false,
        template: {
          closeButton:
            '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 0.700394 -0.713757 0.700394 20 -0.000244141)" fill="#929292"/><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 -0.700394 0.713757 0.700394 1.00003 0.999756)" fill="#929292"/></svg>',
        },
      }
    );
  },
};
</script>

<style>
.popup-info {
  width: 798px;
  padding: 70px 36px;
}

.popup-info__title {
  margin-bottom: 25px;

  font-family: var(--title4-fonts-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--title4-fonts-size);
  line-height: var(--title4-line-height);
  color: var(--title4-color-white);
}

.popup-info____desc {
  margin-bottom: 25px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: 1.5;
  color: var(--subtitle3-color-white);
}

.popup-info__button {
  width: 100%;
  margin-bottom: 25px;
}

@media screen and (max-width: 1599.98px) {
  .popup-info____desc {
    font-size: 12px;
  }
}

@media screen and (max-width: 1199.98px) {
  .popup-info {
    width: 698px;
    padding: 50px 26px;
  }

  .popup-info__title {
    margin-bottom: 15px;
  }

  .popup-info____desc {
    margin-bottom: 15px;
  }

  .popup-info__button {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991.98px) {
  .popup-info {
    width: 620px;
  }

  .popup-info__title {
    margin-bottom: 15px;
  }

  .popup-info____desc {
    font-size: 12px;
  }

  .popup-info__button {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .popup-info {
    width: 500px;
  }
}

@media screen and (max-width: 532.98px) {
  .popup-info {
    width: 290px;
    padding: 20px 20px 30px 20px;
  }

  .popup-info__title {
    margin-bottom: 8px;
  }

  .popup-info____desc {
    margin-bottom: 15px;
  }

  .popup-info__button {
    margin-bottom: 15px;
  }
}
</style>
